/* ORYX App 2.0
*
* Created by ORYX Movement Solutions © 2022
* ==================================================================
 * 
 * Login form Component styling, including the logo
 */

.login_Wrapper {
  display: grid;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  padding: 5%;
}

.login_Modal {
  background-color: white;
  border-radius: 20px;
  text-align: center;
  width: 100%;
}

.test {
  border: 1px solid red;
}

.login_logo {
  max-width: 150px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.login ion-row {
  justify-content: center;
}

.login ion-label {
  color: #737373;
}

ion-item.login_Item {
  --background: #ffffff;

  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
  --highlight-height: 2px;
  --highlight-color-focused: var(--ion-color-primary);
  --highlight-color-valid: var(--ion-color-success600);
  --highlight-color-invalid: var(--ion-color-error600);
}

@media only screen and (max-width: 600px) {
  .login_Modal {
    background-color: transparent;
    color: white;
    max-width: 100%;
    margin: 0;
  }
  p {
    color: white;
  }
  ion-item.login_Item::part(native) {
    --background: rgba(var(--primary-grey100-rgb), 0.15);

    --highlight-height: 2px;
    --highlight-color-focused: #ffffff;
  }
  .login_logo {
    max-width: 100px;
  }
}
