.appBackground {
  --background: #f0f0f7;
}

@media screen and (prefers-color-scheme: dark) {
  .appBackground {
    --background: #000000;
  }
}


.cardHeader {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.cardContent {
  padding: 10px !important;
}

ion-thumbnail {
  --size: 140px;
  --border-radius: 14px;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

.horizontal-center {
  margin: auto;
  display: block;
}

ion-searchbar.md {
  --border-radius: 10px;
}

.uploadIcon {
  height: 100px;
  width: 100px;
  /* position: fixed;
  bottom: 10px; */
  align-self: center;
  color: var(--ion-color-primary-grey200);
}

.scrollable {
  --overflow-y: scroll !important;
  overflow-x: hidden;
  height: calc(100vh - 200px);
  scroll-behavior: auto;
  padding-bottom: 20px;
  margin-top: 10px;
}

ion-card-header {
  height: 72px;
  color: var(--ion-color-primary);
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

ion-card-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--ion-color-primary);
}
ion-card-subtitle {
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  color: var(--ion-text-color);
}
ion-radio {
  width: 30px;
  height: 30px;
}

ion-radio::part(container) {
  border-radius: 8px;
  border: 2px solid #ddd;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: var(--blueGradient);
  border-color: transparent;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #fff;

  transform: rotate(45deg);
}
ion-list-header {
  --color: var(--ion-color-primary);
}

ion-item {
  --background: #ffffff;

  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
  --highlight-height: 2px;
  --highlight-color-focused: var(--ion-color-primary);
  --highlight-color-valid: var(--ion-color-success600);
  --highlight-color-invalid: var(--ion-color-error600);
}

.disabled {
  opacity: 0.2;
}

.destructiveAlert .alert-wrapper .alert-head h2 {
  color: var(--ion-color-error600) !important;
  /* background-color: var(--ion-color-error600); */
}

.informativeAlert .alert-wrapper .alert-head h2 {
  color: var(--ion-color-primary) !important;
  /* background-color: var(--ion-color-error600); */
}

.destructiveAlertButton {
  color: var(--ion-color-error600) !important;
}
ion-popover {
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: fit-content;
}

.border-right {
  border-right: 1px solid #ddd;
}

.modal_Large {
  --width: 90%;
  --height: 90%;
  --border-radius: 10px;
}
