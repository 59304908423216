.wrapper {
  display: grid;
  height: calc(100% - 60px);
  width: 100%;
  align-items: center;
  justify-items: center;
}

.home_logo {
  width: 150px;
  height: 150px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
}
