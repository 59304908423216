.header__logo {
  margin-top: 2px;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}

.Docsheader__logo {
  margin-top: 2px;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}

.Title {
  max-width: 240px;
  border: 1px solid black;
  box-sizing: border-box;
  margin-right: 0px !important;
}

.nav_buttons {
  position: relative;
  margin-left: 300px;
  margin-top: 2px;
}

ion-title > div {
  margin-bottom: 20px;
  text-align: center;
  font-family: inherit;
  color: #737373;
  font-size: 10px;
  font-weight: normal;
  line-height: 1.5;
}

.searchBar {
  border: none !important;
  --box-shadow: none !important;
}
/*  ====================== Header buttons ====================== */
.laguage_Buttons {
  border-right: 1px solid #4d4f5c40;
}

.second_Language_Button {
  margin-left: -15px !important;
}

.language_Buttons_Icon_Language {
  font-size: 30px;
  color: #4d4f5c;
}

.button_Dropdown_Icon {
  /* font-size: 16px; */
  color: #4d4f5c40;
  padding-right: 10px;
}

.user_Button {
  text-transform: none;
}

/* ====================== User menu ====================== */

/* Modal section */
.userMenu .modal-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  width: 215px;
  position: fixed;
  right: 10px;
  top: 60px;
  height: 200px;
  display: flex;
  justify-content: center;
}
.userMenu_List {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  width: 200px;
  text-align: center;
}
.userMenu_List :last-child {
  --border-style: none !important;
}

.userMenu_Container {
  margin: 5px;
}

/* ====================== language menu ====================== */

/* Modal section */
.languageMenu {
  contain: strict;
  height: auto;
}
.languageMenu .modal-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  width: 215px;
  position: fixed;
  right: 200px;
  top: 60px;

  display: flex;
  justify-content: center;
}
.languageMenu_List {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  width: 200px;
  text-align: center;
}
.languageMenu_List :last-child {
  --border-style: none !important;
}

.languagMenu_Container {
  margin: 5px;
}

.TextAlign {
  text-align: center;
}

/* Modal section */
.userInfo-modal .modal-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  width: 450px;
  height: 600px;
}

.userInfo-modal-header {
  height: 72px;
  color: #2066fa;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.userInfo-modal-content {
  top: 84px;
}

.userInfo-modal-listItem {
  margin: 60px 20px;
  border-radius: 4px;
  border: 1px solid #e4e7e8;
}

.userInfo-modal-close {
  height: 80px;
  font-size: 58px;
  /* position: fixed;
  bottom: 10px; */
  align-self: center;
  color: #4d4f5c40;
  --ionicon-stroke-width: 16px;
}
.userInfo-modal-row {
  height: 55vh;
}

.yourSubscriptions-modal-row {
  height: 55vh;
  overflow-y: scroll;
}

.yourSubscriptions-modal-listItem {
  margin: 12px 20px;
  border-radius: 4px;
  border: 1px solid #e4e7e8;
}

.go-to-billing-button {
  display: block;
  position: absolute;
  right: 20px;
  top: 550px;
  width: fit-content;
  color: #4d4f5c;
}

.yourSubscriptions_text {
  color: #4d4f5c;
  margin: 0px 20px 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.subscription_card_img {
  width: 64px;
  margin: 5px 10px;
}
.ORYX_analysis_subscription {
  --background: linear-gradient(#2066fa, #1649d8);
  margin-top: 12px;
  margin-bottom: 0px;
}

.Training_Builder_subscription {
  --background: linear-gradient(#baff6b, #ffe360);
  margin-top: 12px;
  margin-bottom: 0px;
}

.Coaching_subscription {
  --background: linear-gradient(#f75442, #e050a0);
  margin-top: 12px;
  margin-bottom: 0px;
}

.subscription_card_title {
  font-weight: bold;
  color: #ffffff;
  font-size: 18px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: 10px;
}

.subscription_card_title_dark {
  font-weight: bold;
  color: #4d4f5c;
  font-size: 18px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: 10px;
}

.subscription_card_text {
  color: #ffffff;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 10px;
}

.subscription_card_text_dark {
  color: #4d4f5c;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 10px;
}

.Test {
  border: 1px solid purple;
}

/* ====================== Modals ====================== */
.modal_Close {
  height: 80px;
  font-size: 58px;
  /* position: fixed;
  bottom: 10px; */
  align-self: center;
  color: #4d4f5c40;
  --ionicon-stroke-width: 16px;
}
