.exercise_Create_ListItem {
  margin: 12px 20px;
  border-radius: 4px;
  border: 1px solid #e4e7e8;
  margin-left: 5px;
  margin-right: 5px;
}

.createExercise_Card {
  margin-bottom: 20px;
  height: calc(100vh - 120px);
  display: block;
}

.exerciseDetail_Card {
  margin-bottom: 20px;
  height: calc(100vh - 120px);
  display: block;
}
