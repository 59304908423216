/* ORYX App 2.0
*
* Created by ORYX Movement Solutions © 2022
* ==================================================================
 * 
 * Homepage css.
 */

.loginPage {
  --background: url(../../assets/background.svg) no-repeat center center / cover;
  background-position: center;
  background-size: auto;
}
