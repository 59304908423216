.exercisePreviewCard_Thumbnail {
  max-width: fit-content;
  border-radius: 14px;
}
.exercisePreviewCard_Content {
  width: calc(100% - 160px);
}

.exercisePreviewCard_CustomIcon {
  line-height: 25.2px;
}

.exercisePreviewCard_Header {
  border-bottom: 1px solid var(--ion-color-primary-grey200-rgb);
}
.exercisePreviewCard_HeaderText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.exercisePreviewCard_SubtitleText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.uploadArea_box {
  border: 1px dashed var(--ion-color-primary-grey200);
  border-radius: 14px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: var(--light-blue100);
  /* height: 100px; */
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 2px dashed var(--ion-color-primary);
}

/* Hide the input component,
 * see https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
 */
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.createExercise_ThumbnailPreview::part(image) {
  width: 140px;
  height: 140px;
  border-radius: 14px !important;
  object-fit: cover;
}

.createExercise_VideoPreview {
  width: 140px;
  height: 140px;
  border-radius: 14px;
  object-fit: cover;
}

.exerciseLabel {
  font-weight: bold;
  width: fit-content;
}
